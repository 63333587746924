<template>
  <div id="broadcast-one-day-page">
    <div class="inner-broadcast w-100" :class="isThemeHeader === 'true' ? 'bg-dark' : 'bg-white'">
      <SpinnerLoader :loading="isPartialUpdate" :float-in-corner="true" />
      <div class="p-3 d-flex gap-3 w-100 flex-wrap">
        <MultiSelect
          v-model="selects.channels"
          style="width: 250px"
          class="multiselect-sm"
          :options="channel"
          :placeholder="$t('channelModal.channel')"
          label="name"
          track-by="id"
          :allow-empty="false"
          @input="selectChannel"
        ></MultiSelect>

        <datepicker-with-side-buttons
          v-model="selects.day"
          style="width: 250px"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric', weekday: 'short' }"
          :disabled="!selects.channels"
        />

        <MultiSelect
          v-model="selects.measurements"
          style="width: 250px"
          class="multiselect-sm"
          :disabled="isSelectChannel"
          :options="measurementsList"
          :placeholder="$t('channelModal.measurements')"
          label="name"
          track-by="id"
        ></MultiSelect>
        <MultiSelect
          v-model="selects.target"
          style="width: 250px"
          class="multiselect-sm"
          :disabled="isSelectChannel || !selects.measurements"
          :options="targetList"
          :placeholder="$t('channelModal.target')"
          label="name"
          track-by="id"
        ></MultiSelect>
        <MultiSelect
          v-model="selects.commercial"
          :disabled="isSelectChannel"
          style="width: 240px"
          class="multiselect-sm"
          :options="commercialType"
          :placeholder="$t('table.selectCommercialType')"
          label="name"
          track-by="id"
        ></MultiSelect>
        <!-- <MultiSelect
          v-model="selects.block"
          :disabled="isSelectChannel"
          style="width: 240px"
          class="multiselect-sm"
          :options="blockTypes"
          :placeholder="$t('table.selectBlockType')"
          label="name"
          track-by="id"
        ></MultiSelect> -->
        <MultiSelect
          v-model="selects.agency"
          :disabled="isSelectChannel"
          style="width: 250px"
          class="multiselect-sm"
          :options="agenciesListWithAll"
          :placeholder="$t('table.selectAgency')"
          label="name"
          track-by="id"
        ></MultiSelect>
        <MultiSelect
          v-model="selects.advertiser"
          :disabled="isSelectChannel || !(selects.agency && selects.agency.id)"
          style="width: 250px"
          class="multiselect-sm"
          :options="advertisersListWithAll"
          :placeholder="$t('table.selectAdvertiser')"
          label="name"
          track-by="id"
        ></MultiSelect>
        <MultiSelect
          v-model="selects.brand"
          :disabled="isSelectChannel || !(selects.advertiser && selects.advertiser.id)"
          style="width: 250px"
          class="multiselect-sm"
          :options="brandsListWithAll || []"
          :placeholder="$t('table.selectGroup')"
          label="name"
          track-by="id"
        ></MultiSelect>
        <MultiSelect
          v-model="selects.placementType"
          :disabled="isSelectChannel"
          style="width: 250px"
          class="multiselect-sm"
          :options="placementType"
          :placeholder="$t('table.selectType')"
          label="name"
          track-by="id"
        ></MultiSelect>

        <label class="small mb-0 mt-1" for="select-time-slot">{{ $t('table.versionControl') }}</label>
        <select id="select-time-slot" v-model="selectedTimeSlot" class="custom-select custom-select-sm" style="width: 50px" :disabled="isSelectChannel">
          <option class="option" value="">{{ $t('table.off') }}</option>
          <option class="option" value="0.5">0.5</option>
          <option class="option" value="1">1</option>
          <option class="option" value="3">3</option>
          <option class="option" value="6">6</option>
          <option class="option" value="24">24</option>
        </select>
      </div>
    </div>
    <div class="d-flex w-100">
      <div
        class="broadcast-left"
        :class="[isThemeHeader === 'true' ? 'form-con__dark' : 'form-con', isSelectChannel ? 'w-0' : '', hiddenPanel ? 'hide-right-panel' : '']"
      >
        <transition name="fade" mode="out-in">
          <div
            v-if="broadcastOneDayStatus === 'loading'"
            class="wrapper-broadcast__loader"
            :class="[isThemeHeader === 'true' ? 'form-con__dark' : 'form-con', hiddenPanel ? 'hide-right-panel' : '']"
          >
            <SpinnerLoader :loading="broadcastOneDayStatus" />
          </div>
        </transition>
        <div
          v-if="broadcastOneDayStatus === 'success' && !isSelectChannel"
          id="OneDayTable__wrapper"
          class="table table-responsive table-body-broadcast pl-3 b-table-sticky-header"
        >
          <table v-if="broadcastOneDay" id="oneDayTable" class="table table-sm table-hover w-auto mr-auto">
            <thead @contextmenu.prevent="$refs.oneDayTableHeaderMenu.open($event, {})">
              <tr>
                <th v-if="selectedTimeSlot" rowspan="2" name="timeSlot">
                  <div class="resizable-container">
                    {{ $t('table.timeSlot') }}
                  </div>
                </th>
                <th rowspan="2" name="programTime">
                  <div class="resizable-container">
                    {{ $t('table.time') }}
                    <button type="button" class="d-inline-block btn-transparent" :title="$t('booking.clearSort')" @click="clearSort">
                      <b-icon font-scale="0.75" icon="arrow-down" variant="danger"></b-icon>
                    </button>
                  </div>
                </th>
                <th rowspan="2" name="programName">
                  <div class="resizable-container">
                    {{ $t('broadcastSideBar.program') }}
                  </div>
                </th>
                <th colspan="4" style="padding: 2px 0 2px 6px">{{ $t('broadcastSideBar.block') }}</th>
                <th v-if="auctionEnabled === 'TRUE'" name="coefficient" rowspan="2">
                  <div class="resizable-container">{{ $t('auction.bidCoefficient') }}</div>
                </th>
                <th rowspan="2" name="spotId"><div class="resizable-container">ID</div></th>
                <th rowspan="2" name="spotName">
                  <div class="resizable-container">
                    {{ $t('table.spotName') }}
                  </div>
                </th>
                <th rowspan="2" name="mediaPlan">
                  <div class="resizable-container">{{ $t('table.mediaPlan') }}</div>
                </th>
                <th rowspan="2" name="spotVersion">
                  <div class="resizable-container">{{ $t('table.version') }}</div>
                </th>
                <th rowspan="2" name="spotDuration">
                  <div class="resizable-container">{{ $t('table.durationShort') }}</div>
                </th>
                <th rowspan="2" name="spotPosition">
                  <div class="resizable-container">{{ $t('table.position') }}</div>
                </th>
                <th rowspan="2" name="spotOnChannel">
                  <div class="resizable-container">
                    {{ $t('table.onChannel') }}
                  </div>
                </th>
                <th rowspan="2" name="spotType">
                  <div class="resizable-container">{{ $t('table.type') }}</div>
                </th>
                <th rowspan="2" :class="isAdvertiserVisible ? '' : 'd-none'" name="spotAdvertiser">
                  <div class="resizable-container">{{ $t('table.advertiser') }}</div>
                </th>
                <th rowspan="2" :class="isBrandVisible ? '' : 'd-none'" name="spotBrand">
                  <div class="resizable-container">{{ $t('table.brand') }}</div>
                </th>
                <th rowspan="2" :class="isGroupVisible ? '' : 'd-none'" name="spotGroup">
                  <div class="resizable-container">{{ $t('table.group') }}</div>
                </th>
              </tr>
              <tr>
                <th name="blockTime">
                  <div class="resizable-container gavel-sign-fixer">{{ $t('table.time') }}</div>
                </th>
                <th name="blockDuration">
                  <div class="resizable-container">{{ $t('booking.total') }}/{{ secondBlockParam === 1 ? $t('table.occupied') : $t('table.freeShort') }}</div>
                </th>
                <th name="blockRating">
                  <div class="resizable-container">{{ $t('table.rating') }}</div>
                </th>
                <th name="blockType">
                  <div class="resizable-container">{{ $t('table.type') }}</div>
                </th>
              </tr>
            </thead>
            <tbody class="overflow-hidden">
              <template v-for="(timeSlot, indexT) in broadcastOneDay.data.grid">
                <tr v-if="selectedTimeSlot" :key="indexT">
                  <td :rowspan="countSpotsInBlocksInPrograms(timeSlot)" name="timeSlot" :class="checkVersions(timeSlot.commercial_version_type_totals)">
                    {{ timeSlot.time_period_start_at_time }}-{{ timeSlot.time_period_end_at_time }}<br />
                    <template v-for="(cmt, indexC) in timeSlot.commercial_version_type_totals">
                      {{ indexC > 0 ? '/' : '' }}
                      <abbr
                        :key="indexT + '_' + indexC"
                        :set="(tmpCVtype = commercialVersionTypes.data.find((el) => el.id === cmt.commercial_version_type_id))"
                        :title="tmpCVtype ? tmpCVtype.name : ''"
                      >
                        {{ cmt.total }}
                      </abbr>
                    </template>
                  </td>
                  <td colspan="17" class="p-0 border-0 cursor-default"></td>
                </tr>
                <template v-for="(program, indexP) in timeSlot.data">
                  <tr :key="indexT + '_' + indexP" class="border-top" :set="(countedSpots = countSpotsInBlocks(program.blocks))">
                    <td :rowspan="countedSpots" name="programTime">
                      <template v-if="program.program_release_start_interval || program.blocks[0]">
                        {{
                          program.program_release_start_interval
                            ? sec2time(program.program_release_start_interval) + '-' + sec2time(program.program_release_end_interval)
                            : sec2time(program.blocks[0].block_start_interval) + '-' + sec2time(program.blocks[0].block_end_interval)
                        }}
                      </template>
                    </td>
                    <td :rowspan="countedSpots" name="programName">
                      <small v-if="isAdmin && showProgramReleaseID && program.program_release_id" class="inline-block text-muted">
                        [{{ program.program_release_id }}]
                      </small>
                      <template v-if="program.program_release_program_name">
                        {{ program.program_release_program_name }} {{ program.program_release_name ? '&ndash; ' + program.program_release_name : '' }}
                      </template>
                      <template v-else>
                        <i>{{ $t('table.crossProgramBlock') }}</i>
                      </template>
                    </td>
                    <td colspan="15" class="p-0 border-0 cursor-default"></td>
                  </tr>
                  <template v-for="(block, indexB) in program.blocks">
                    <tr
                      :key="indexT + '_' + indexP + '_' + indexB"
                      class="border-0-first-row border border-danger"
                      :class="isThrottledByRequest || isThrottledByTimeout ? 'cursor-throttled' : 'cursor-pointer'"
                      :set="(spotsCount = block.spots.length + 1)"
                      @click="addCommercial(block, $event)"
                    >
                      <td
                        :rowspan="spotsCount"
                        name="blockTime"
                        class="position-relative border border-danger border-right-0"
                        :class="block.block_type_id == 2 ? 'border-left-2' : 'border-left-0'"
                      >
                        {{ sec2time(block.block_start_interval) }}
                        <div v-if="block.block_type_id == 2" class="overflow-visible text-danger position-absolute pb-1 gavel-position">
                          <IconGavel height="12"></IconGavel>
                        </div>
                        <div v-show="block.is_fixed_price" class="overflow-visible text-danger position-absolute pb-1 gavel-position font-weight-bold">
                          <IconCrown height="12"></IconCrown>
                        </div>
                      </td>
                      <td :rowspan="spotsCount" name="blockDuration" class="border border-danger border-left-0 border-right-0">
                        {{ block.block_duration }}/{{ showBlockSecondParam(block, selects.mediaPlan) }}
                      </td>
                      <td :rowspan="spotsCount" name="blockRating" class="border border-danger border-left-0 border-right-0">
                        {{ getBlockRating(block.grps, grpByDays, selects.measurements, selects.target) }}/{{
                          getBlockFactRating(block.fact_grps, selects.measurements, selects.target)
                        }}
                      </td>
                      <td
                        :rowspan="spotsCount"
                        name="blockType"
                        class="border border-danger border-left-0 border-right-0"
                        :set="(ct = commercialType.find((el) => el.id === block.block_commercial_type_id))"
                      >
                        {{ ct ? ct.name : '' }}
                      </td>
                      <td
                        colspan="11"
                        class="p-0 border-0 cursor-default"
                        @click.stop
                        @drop="dropSpot(block.block_id, $event)"
                        @dragenter.prevent
                        @dragover.prevent
                      ></td>
                    </tr>
                    <tr
                      v-for="(spot, indexS) in block.spots"
                      :id="indexT + '_' + indexP + '_' + indexB + '_' + indexS"
                      :key="indexT + '_' + indexP + '_' + indexB + '_' + indexS"
                      class="border-0-first-row"
                      :class="{ 'text-muted': !spot.is_active, 'prohibition-warning': spot.coProhibition }"
                      :draggable="canMoveSpotUp && !isDisabledOperations"
                      @dragenter.prevent
                      @dragover.prevent
                      @dragstart="dragSpotStart(spot, indexT + '_' + indexP + '_' + indexB + '_' + indexS, $event)"
                      @drop="dropSpot(block.block_id, $event)"
                    >
                      <td v-if="auctionEnabled === 'TRUE'" name="coefficient">
                        <div v-if="block.block_type_id == 2">
                          {{ ((spot.spot_auction_coeff ?? 0) * 100) | toFixedAndSpace }}%
                          <div
                            v-if="spot.placement_type_id != 3 && spot.placement_type_id != 4"
                            class="float-right btn btn-outline-secondary btn-sm h-100 d-inline-block pt-0"
                            style="line-height: 100%; padding-bottom: 2px"
                            :title="$t('table.edit')"
                            @click="showBidModal(spot, block.auction_step_coeff)"
                          >
                            <icon-gavel height="10px"></icon-gavel>
                            <!-- <b-icon icon="pencil" /> -->
                          </div>
                        </div>
                      </td>
                      <td name="spotId">
                        <div v-if="canSwapSpot && !isDisabledOperations" class="custom-control custom-radio custom-control-inline commercial-radio">
                          <input
                            :id="`radio-comm-${indexT}-${indexP}-${indexB}-${indexS}`"
                            v-model="selectedCommercialRow"
                            type="radio"
                            name="comm-radios"
                            class="custom-control-input"
                            :value="spot"
                          />
                          <label class="custom-control-label" :for="`radio-comm-${indexT}-${indexP}-${indexB}-${indexS}`">
                            {{ spot.commercial_id }}
                            <small v-if="isAdmin && showSpotID" class="text-muted"><abbr :title="$t('table.spotId')">S:</abbr>[{{ spot.spot_id }}]</small>
                          </label>
                        </div>
                        <template v-else>
                          {{ spot.commercial_id }}
                        </template>
                      </td>
                      <td
                        :class="spot.status === 0 ? 'new-commercial' : ''"
                        class="cursor-pointer position-relative"
                        name="spotName"
                        @click="[(isOpenModal = 'modal-commercial'), (modalName = 'edit'), (currentCommercial = spot)]"
                        @contextmenu.prevent="showAddToPocketModal(spot, $event)"
                      >
                        <template v-if="spot.height">
                          <span class="ds-span-line" :style="{ height: spot.height + 'px', left: spot.left + 'px' }"></span>
                        </template>
                        <span class="commercial-name" :style="{ paddingLeft: Math.max(...allDsCount) * 2 + 16 + 'px' }">
                          {{ spot.commercial_name }}
                        </span>
                        <CoBrandBadge v-if="spot.co_branding_discount !== null" />
                      </td>
                      <td name="mediaPlan">
                        {{ spot.mediaplan_name }}
                      </td>
                      <td name="spotVersion" :set="(tempCVT = commercialVersionTypes.data.find((el) => el.id === spot.commercial_version_type_id))">
                        {{ tempCVT ? tempCVT.name : '' }}
                      </td>
                      <td name="spotDuration">
                        {{ spot.duration }}
                      </td>
                      <td name="spotPosition" :class="{ 'bg-position-warning': spot.position && spot.spot_equal_fact_position === 0 }">
                        {{ spot.is_active ? spot.position : 'Wait' }}
                      </td>
                      <td name="spotOnChannel">
                        <span :class="spot.is_on_channel ? 'green-circle' : 'red-circle'">
                          {{ spot.is_on_channel ? $t('table.yes') : $t('table.no') }}
                        </span>
                      </td>
                      <td name="spotType">
                        <span
                          :class="setPlacementClass(spot.placement_type_id)"
                          :set="(tempPT = placementType.find((type) => type.id === spot.placement_type_id))"
                        >
                          {{ tempPT ? tempPT.name : '' }}
                        </span>
                      </td>
                      <td
                        :class="[checkSameNeighbor('advertiser', spot, block.spots[indexS + 1], block.spots[indexS - 1]), isAdvertiserVisible ? '' : 'd-none']"
                        name="spotAdvertiser"
                      >
                        {{ spot.advertiser_name }}
                      </td>
                      <td :class="isBrandVisible ? '' : 'd-none'" name="spotBrand">
                        {{ spot.brand_name }}
                      </td>
                      <td
                        :class="[checkSameNeighbor('brand_group', spot, block.spots[indexS + 1], block.spots[indexS - 1]), isGroupVisible ? '' : 'd-none']"
                        name="spotGroup"
                      >
                        {{ spot.brand_group_name }}
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <div id="dragMe" class="sidebar-resizer"></div>

      <div
        v-if="isSelectChannel"
        class="broadcast-left d-flex justify-content-center align-items-center"
        :class="isThemeHeader === 'true' ? 'form-con__dark' : 'form-con'"
      >
        <div style="font-size: 18px" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">{{ $t('booking.selectChannelAndDate') }}</div>
      </div>

      <div
        class="broadcast-right d-flex flex-column"
        :class="[isThemeHeader === 'true' ? 'form-con__dark' : 'form-con ', isSelectChannel ? '' : 'floating', hiddenPanel ? 'hide-right-panel' : '']"
      >
        <div v-if="!isSelectChannel" id="hide-panel-container" class="d-flex flex-wrap gap-2 mb-2">
          <b-button
            id="btn-hide-panel"
            variant="link"
            size="sm"
            class="text-decoration-none border-0"
            :class="[isThemeHeader === 'true' ? 'white-color' : 'dark-color bg-white', hiddenPanel ? 'hide-right-panel border' : '']"
            @click="hiddenPanel = !hiddenPanel"
          >
            <b-icon id="toggle-panel-icon" icon="arrow-bar-right" :flip-h="hiddenPanel"></b-icon>
            {{ hiddenPanel ? /*$t("booking.showPanel")*/ '' : $t('booking.hidePanel') }}
          </b-button>
          <b-button
            v-if="canMoveSpotUp"
            size="sm"
            :disabled="!(selectedCommercialRow && !selectedCommercialRow.position && selectedCommercialRow.is_active) || isDisabledOperations"
            @click="upSpot($event)"
          >
            {{ $t('booking.up') }}
          </b-button>
          <b-button
            v-if="canMoveSpotDown"
            size="sm"
            :disabled="!(selectedCommercialRow && !selectedCommercialRow.position && selectedCommercialRow.is_active) || isDisabledOperations"
            @click="downSpot($event)"
          >
            {{ $t('booking.down') }}
          </b-button>
          <b-button v-if="$checkPermissions('export.channel_broadcasting')" v-b-modal.export-playlist size="sm">
            {{ $t('broadcastSideBar.playList') }}
          </b-button>
          <b-button
            v-if="canSwapSpot"
            size="sm"
            :disabled="
              !(selectedCommercialRow && selectedCommercialRow.is_active && selectedCommercial) ||
              (selects.mediaPlan && selects.mediaPlan.is_closed) ||
              isDisabledOperations
            "
            @click="replaceSpot"
          >
            {{ $t('booking.replaceSpot') }}
          </b-button>
          <b-button v-if="$checkPermissions('spot.delete')" v-b-modal.confirm-clear-query size="sm" :disabled="isDisabledOperations">
            {{ $t('booking.clearQueue') }}
          </b-button>

          <div
            v-if="
              $checkPermissions('spot.fix') &&
              (canFixLowPriority ||
                (!canFixLowPriority &&
                  selectedCommercialRow &&
                  (selectedCommercialRow.placement_type_id === 1 || selectedCommercialRow.placement_type_id === 2))) &&
              selectedCommercialRow &&
              !selectedCommercialRow.position &&
              selectedCommercialRow.is_active
            "
            class="d-flex gap-2"
          >
            <b-form-select v-model="selectedPosition" size="sm" :options="premiumPositionOptions" class="w-50"></b-form-select>
            <b-button
              size="sm"
              class="w-50"
              :disabled="!(selectedPosition && selectedCommercialRow && selectedCommercialRow.is_active) || isBusy || isDisabledOperations"
              @click="fixSpot"
            >
              {{ $t('booking.fix') }}
            </b-button>
          </div>
          <b-button
            v-if="
              $checkPermissions('spot.unfix') &&
              (canFixLowPriority ||
                (!canFixLowPriority &&
                  selectedCommercialRow &&
                  (selectedCommercialRow.placement_type_id === 1 || selectedCommercialRow.placement_type_id === 2))) &&
              selectedCommercialRow &&
              selectedCommercialRow.position &&
              selectedCommercialRow.is_active
            "
            size="sm"
            :disabled="!(selectedCommercialRow && selectedCommercialRow.position && selectedCommercialRow.is_active) || isBusy || isDisabledOperations"
            @click="unfixSpot"
          >
            {{ $t('booking.unFix') }}
          </b-button>
        </div>

        <div v-if="!isSelectChannel" class="flex-fill oneday-sidebar-inner-wrapper">
          <MultiSelect
            v-model="selects.project"
            class="mb-2 multiselect-sm"
            :options="projects"
            :placeholder="$t('table.selectProject')"
            label="name"
            track-by="id"
          ></MultiSelect>
          <MultiSelect
            v-model="selects.order"
            class="mb-2 multiselect-sm"
            :options="orders"
            :placeholder="$t('table.selectOrder')"
            label="name"
            track-by="id"
            :disabled="!selects.project"
          ></MultiSelect>
          <MultiSelect
            v-model="selects.mediaPlan"
            class="mb-1 multiselect-sm"
            :options="mediaPlans"
            :placeholder="$t('table.selectMediaPlan')"
            label="name"
            track-by="id"
            :disabled="!selects.order"
          ></MultiSelect>

          <BlockSecondParamSelector v-model="secondBlockParam" />

          <template v-if="modalEditMediaPlans && selects.mediaPlan">
            <h6 class="mt-2">
              <WarningClosedMP v-if="selects.mediaPlan.is_closed" />
              {{ $t('sideBar.commercials') }}
              <span class="small"> ({{ selects.mediaPlan.date_from | convertDate }} – {{ selects.mediaPlan.date_to | convertDate }}) </span>
            </h6>

            <SelectableCommercialsTable
              ref="selectableTableWrapper"
              v-model="selectedCommercial"
              :media-plan-commercials.sync="commercialItems"
              :is-disabled="selects.mediaPlan.is_closed"
              @clearPocketTableSelected="clearPocketTableSelected"
            />
          </template>

          <div v-if="canCreate" class="d-flex gap-3 flex-column mt-3">
            <div class="d-flex gap-3 align-items-center">
              <h6 class="mb-1">{{ $t('booking.pocket') }}</h6>
              <b-button style="font-size: 12px" size="sm" :disabled="pocketList.length < 1" @click="clearPocket">
                {{ $t('booking.clearPocket') }}
              </b-button>
            </div>
            <div class="pocket-list__wrapper">
              <b-table
                v-if="pocketList.length > 0"
                ref="pocketTable"
                :fields="[
                  { key: 'name', label: $t('table.name') },
                  { key: 'duration', label: $t('table.durationShort') },
                  { key: 'version', label: $t('table.version') },
                ]"
                :items="pocketList"
                responsive
                selectable
                select-mode="single"
                class="table-sm small pocket-table"
                :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
                @row-selected="onPocketRowSelected"
              >
              </b-table>
            </div>
          </div>

          <WgrpTable v-if="modalEditMediaPlans && selects.mediaPlan" class="mt-3" :commercial-items="commercialItems" />
        </div>

        <div class="py-2 mt-auto align-self-end">
          <b-button size="sm" @click="$router.go(-1)">
            {{ $t('broadcastSideBar.exit') }}
          </b-button>
        </div>
      </div>
    </div>

    <ModalConfirmInQuery @confirmInQuery="confirmInQuery" />
    <ModalConfirmWithoutPremiumPosition @confirmWithoutPremium="confirmWithoutPremium" />

    <!-- MODAL CONFIRM ADD TO POCKET -->
    <b-modal
      id="confirm-add-to-pocket"
      ref="confirm-add-to-pocket"
      size="sm"
      :title="$t('booking.confirmAddToPocket')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="confirmAddToPocket"
    >
      <p class="my-2">{{ $t('booking.confirmAddToPocket') }}? {{ $t('booking.spotWillBeDeleted') }}</p>
    </b-modal>
    <!-- MODAL CONFIRM ADD TO POCKET -->

    <!-- MODAL CONFIRM CLEAR QUERY -->
    <b-modal
      id="confirm-clear-query"
      ref="confirm-clear-query"
      size="sm"
      :title="$t('booking.clearQueueInDay')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      @ok="clearQueue"
    >
      <p class="my-2">{{ $t('table.areYouSure') }}</p>
    </b-modal>
    <!-- MODAL CONFIRM CLEAR QUERY -->

    <ModalCommercial
      :commercial="currentCommercial"
      :modal-name="modalName"
      :is-open-modal="isOpenModal"
      :is-one-day="true"
      :can-edit="canEditCommercial"
      @update="getOneDayInfo"
      @isOpenModal="isOpenModal = null"
    />

    <ModalExportPlaylist
      :channel="selects.channels"
      :agency="selects.agency"
      :date="selects.day"
      :advertiser="selects.advertiser"
      :brand="selects.brand"
      :placement_type="selects.placementType"
      :commercial_type="selects.commercial"
      :measurement_company="selects.measurements"
      :target_audience="selects.target"
    />

    <ModalBid
      :update="bidModalUpdate"
      :bid="spot?.spot_auction_coeff ?? 0"
      :step="auctionStep ?? 0"
      :block-id="spot?.block_id ?? 0"
      @increaseBidConfirm="(coeff) => increaseBid(coeff)"
    >
    </ModalBid>

    <ContextMenu ref="oneDayTableHeaderMenu">
      <ContextMenuItem @click.native="showOrHideCol('advertiser')">
        <b-icon :icon="isAdvertiserVisible ? 'eye' : 'eye-slash'" /> {{ $t('table.advertiser') }}
      </ContextMenuItem>
      <ContextMenuItem @click.native="showOrHideCol('brand')">
        <b-icon :icon="isBrandVisible ? 'eye' : 'eye-slash'" /> {{ $t('table.brand') }}
      </ContextMenuItem>
      <ContextMenuItem @click.native="showOrHideCol('group')">
        <b-icon :icon="isGroupVisible ? 'eye' : 'eye-slash'" /> {{ $t('table.group') }}
      </ContextMenuItem>
    </ContextMenu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MultiSelect from '@/components/MultiSelect';
//import errorsHandler from "@/utils/errorsHandler";
import SpinnerLoader from '../components/SpinnerLoader';
import ModalCommercial from '@/components/Commercials/ModalCommercial';
import setPlacementClass from '@/mixins/setPlacementClass';
import ModalConfirmInQuery from '@/components/Booking/ModalConfirmInQuery';
import ModalConfirmWithoutPremiumPosition from '@/components/Booking/ModalConfirmWithoutPremiumPosition';
import sortTableMixin from '@/mixins/sortTable';
import showDoubleSpots from '@/mixins/showDoubleSpots';
import oneDay from '@/mixins/oneDay';
import ModalExportPlaylist from '@/components/Broadcast/ModalExportPlaylist';
import { BTable } from 'bootstrap-vue';
import getBlockRatings from '@/mixins/getBlockRatings';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import DatepickerWithSideButtons from '@/components/DatepickerWithSideButtons.vue';
import convertDate from '../filters/convertDate';
import showBlockSecondParam from '@/mixins/showBlockSecondParam';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';
import IconGavel from '@/components/icons/IconGavel.vue';
import IconCrown from '@/components/icons/IconCrown.vue';
import ModalBid from '@/components/Booking/ModalBid.vue';
import toFixedAndSpace from '@/filters/toFixedAndSpace';

export default {
  name: 'BroadcastOneDay',
  components: {
    SpinnerLoader,
    MultiSelect,
    ModalCommercial,
    ModalConfirmInQuery,
    ModalConfirmWithoutPremiumPosition,
    ModalExportPlaylist,
    DatepickerWithSideButtons,
    BTable,
    ContextMenu,
    ContextMenuItem,
    WgrpTable: () => import('@/components/Booking/WgrpTable'),
    SelectableCommercialsTable: () => import('@/components/Booking/SelectableCommercialsTable'),
    BlockSecondParamSelector: () => import('@/components/BlockSecondParamSelector'),
    WarningClosedMP: () => import('@/components/Booking/WarningClosedMP.vue'),
    CoBrandBadge: () => import('@/components/CoBrandBadge.vue'),
    IconGavel,
    IconCrown,
    ModalBid,
  },
  filters: {
    convertDate,
    toFixedAndSpace,
  },
  mixins: [setPlacementClass, sortTableMixin, showDoubleSpots, oneDay, getBlockRatings, showBlockSecondParam, timeConvertHelpers],
  props: {
    measurement_id: { type: [Number, String], default: undefined },
    target_id: { type: [Number, String], default: undefined },
    commercial_type_id: { type: [Number, String], default: undefined },
    block_type_id: { type: [Number, String], default: undefined },
    channel_id: { type: [Number, String], default: undefined },
    date: { type: String, default: undefined },
    agency_id: { type: [Number, String], default: undefined },
    advertiser_id: { type: [Number, String], default: undefined },
    project_id: { type: [Number, String], default: undefined },
    order_id: { type: [Number, String], default: undefined },
    mediaplan_id: { type: [Number, String], default: undefined },
    brand_id: { type: [Number, String], default: undefined },
    placement_type_id: { type: [Number, String], default: undefined },
    time_slot: { type: [Number, String], default: undefined },
    sort_mode: { type: String, default: undefined },
  },
  data() {
    return {
      spot: null,
      auctionStep: null,
      bidModalUpdate: true, //bid modal updates when this value changes
      selectedTimeSlot: 6,
      isPartialUpdate: '',
      updateGridTimeout: parseInt(process.env.VUE_APP_DELAYED_UPDATE_TIMEOUT) || 0,
      updateGridTimeoutId: null,
      isThrottledByRequest: false,
      isThrottledByTimeout: false,
      throttleTimeout: parseInt(process.env.VUE_APP_THROTTLE_SPOT_PLACEMENT) || 0,
      throttleTimeoutId: null,
      auctionEnabled: process.env.VUE_APP_ENABLE_AUCTIONS,
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      broadcastOneDay: 'getBroadcastOneDay',
      broadcastOneDayStatus: 'getBroadcastOneDayStatus',
      isThemeHeader: 'isTheme',
      target: 'getTarget',
      channel: 'getChannel',
      measurementsList: 'getMeasurementsList',
      targetList: 'getTargetList',
      agenciesListWithAll: 'getAgenciesListWithAll',
      advertisersListWithAll: 'getAdvertisersListWithAll',
      projects: 'getProjectsList',
      orders: 'getOrdersList',
      mediaPlans: 'getMediaPlansList',
      modalEditMediaPlans: 'getModalEditMediaPlans',
      commercialVersionTypes: 'getCommercialVersionTypes',
      commercialType: 'getCommercialType',
      blockTypes: 'getBlockTypes',
      placementType: 'getPlacementType',
      brandsListWithAll: 'getBrandsListWithAll',
      isAdmin: 'getIsCurrentUserAdmin',
      showSpotID: 'getShowSpotID',
      showProgramReleaseID: 'getShowProgramReleaseID',
    }),
  },
  watch: {
    selectedTimeSlot() {
      if (!this.isSelectChannel && this.selectedTimeSlot) {
        this.getOneDayInfo();
      }
    },
  },
  created() {
    document.title = this.$i18n.t('table.versionControl') + ' – OpenMediaLogic';
  },
  async mounted() {
    this.resizeLayout(); //init resizer
    this.$el.addEventListener('DOMContentLoaded', this.resizeLayout);
    await this.loadProps();
  },
  updated: function () {
    this.$nextTick(function () {
      if (this.broadcastOneDayStatus === 'success' && !this.isSelectChannel && this.posY >= 10) {
        // restore scroll pos after some action
        const broadcastTable = document.getElementById('OneDayTable__wrapper');
        const TABLE_Y_POS = broadcastTable.getBoundingClientRect().top;
        broadcastTable.scrollTo({ top: this.posY - this.clientY + TABLE_Y_POS });
        broadcastTable.scrollTo({ left: this.posX - this.clientX });
        this.posY = 0;
        this.clientY = 0;
        this.posX = 0;
        this.clientX = 0;
      }
    });
  },
  beforeDestroy() {
    this.setUserColumns('broadcastOneDayColWidth');
  },
  destroyed() {
    this.$store.commit('clearAdvertisersList');
    this.$store.commit('clearBrandsList');
    this.$store.commit('clearProjects');
    this.$store.commit('clearOrders');
    this.$store.commit('clearMediaPlans');
    this.$store.commit('clearTargetsList');
    this.$store.commit('clearBroadcastOneDay');
    this.$el.removeEventListener('DOMContentLoaded', this.resizeLayout);
  },
  methods: {
    // !!! Most of logic is in oneDay.js mixin !!!

    async buildOneDayTable(updated_block) {
      // this.broadcastOneDay.data.grid.forEach((timeSlot) => {
      for (let timeSlot of this.broadcastOneDay.data.grid) {
        if (timeSlot.data) {
          timeSlot.data.forEach((program) => {
            program.blocks.forEach((block, index, arr) => {
              if (typeof updated_block !== 'undefined' && updated_block.block_id === block.block_id) {
                this.$set(arr, index, (block = { ...block, ...updated_block }));
              }
              if (block.spots.length > 1) {
                //clear params on every block
                this.usedDsId = [];
                this.doubleSpotsCount = 0;
                this.showDoubleSpots(block, 22.78, 21); // rowHeight and innerDivHeight
                this.allDsCount.push(this.doubleSpotsCount); // to get max count in template
              }
            });
          });
        }
      }
    },

    async getOneDayInfo(withLoading) {
      if (!withLoading) this.isPartialUpdate = 'loading';
      this.setUserColumns('broadcastOneDayColWidth');
      this.updateQuery();
      if (withLoading) this.$store.commit('getBroadcastOneDayRequest');
      this.selectedCommercialRow = null;
      await this.$store.dispatch('GET_BROADCAST_ONE_DAY', {
        data: this.selects.channels.id,
        date: {
          date: this.selects.day,
          agency_id: this.selects.agency ? this.selects.agency.id : null,
          advertiser_id: this.selects.advertiser ? this.selects.advertiser.id : null,
          brand_id: this.selects.brand ? this.selects.brand.id : null,
          placement_type_id: this.selects.placementType ? this.selects.placementType.id : null,
          commercial_type_id: this.selects.commercial ? this.selects.commercial.id : null,
          block_type_id: this.selects.block ? this.selects.block.id : null,
          measurement_company_id: this.selects.measurements ? this.selects.measurements.id : null,
          target_audience_id: this.selects.target ? this.selects.target.id : null,
          time_period: this.selectedTimeSlot ? +this.selectedTimeSlot * 60 * 60 : null,
          sort: this.sortString ? this.sortString : null,
        },
      });
      if (this.broadcastOneDayStatus === 'success') {
        setTimeout(() => this.restoreUserColumns('broadcastOneDayColWidth', this.broadcastOneDayStatus), 50); //delayed to call after table was rendered
        this.buildOneDayTable();
      }
      this.isPartialUpdate = '';
    },

    countSpotsInBlocksInPrograms(timeSlot) {
      let acc = 1;
      if (timeSlot.data) {
        timeSlot.data.forEach((program) => {
          program.blocks.forEach((block) => {
            acc += block.spots.length + 1;
          });
          acc += 1;
        });
      }
      return acc;
    },

    checkVersions(totals) {
      const numArray = totals.map((el) => el.total);
      const minNum = Math.min(...numArray);
      const maxNum = Math.max(...numArray);
      return Math.abs(minNum / maxNum - 1) >= 0.05 ? 'timeslot-red' : '';
    },
  },
};
</script>

<style lang="sass">
@use "~/src/assets/sass/broadcast.sass"
@use "~/src/assets/sass/oneDayTable.sass"
</style>
<style lang="sass" src="../assets/sass/mediaPlanPlacementTypes.sass"></style>

<style lang="scss" scoped>
@use '~/src/assets/sass/oneDay.sass';
</style>
